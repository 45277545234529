import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Glovo from "../img/glovo2.jpg";
import GooglePlay from "../img/google_play.png";
import Logo from "../img/logo-big.png";
import Pyszne from "../img/pyszne2.jpg";
import AppStore from "../SVG/Footer/app_store.svg";
import Email from "../SVG/Footer/email.svg";
import Facebook from "../SVG/Footer/facebook2.svg";
import Instagram from "../SVG/Footer/instagram2.svg";
import Lokalizacja from "../SVG/Footer/lokalizacja.svg";
import Telefon from "../SVG/Footer/telefon2.svg";

const MainDiv = styled.div`
	background-color: #fdf9e7;

	scroll-margin-top: 180px;

	@media (max-width: 1024px) {
		scroll-margin-top: 130px;
	}
`;
const InfoText = styled.span`
	font-size: 20px;
	font-weight: bold;

	@media (max-width: 1280px) {
		font-size: 15px;
	}
`;

const Title = styled.span`
	font-size: 50px;
	font-weight: 700;

	@media (max-width: 1280px) {
		font-size: 30px;
	}
`;
const BottomText = styled.span`
	font-size: 20px;
	font-weight: 200;

	@media (max-width: 1280px) {
		font-size: 15px;
	}
`;
const TimeText = styled.span`
	font-size: 25px;
	font-weight: 600;

	@media (max-width: 1280px) {
		font-size: 20px;
	}
`;
const AppsImg = styled.img`
	width: 220px;
	/* width: 100%; */

	@media (max-width: 1280px) {
		width: 170px;
	}
`;
const LogoImg = styled.img`
	width: 100%;

	@media (max-width: 1024px) {
		width: 80%;
	}
	@media (max-width: 768px) {
		width: 40%;
	}
	@media (max-width: 500px) {
		width: 70%;
	}
`;
const DeliveryDiv = styled.div`
	top: -35px;
	right: 0;

	font-size: 25px;
	font-weight: 200;

	@media (max-width: 1280px) {
		font-size: 20px;
	}
`;
const DeliveryTimeSpan = styled.span`
	font-size: 25px;
	font-weight: 200;

	@media (max-width: 1280px) {
		font-size: 20px;
	}
`;

function Footer({ t, isUnder640px }) {
	return (
		<MainDiv id="contact">
			<div className="container mx-auto pt-10 sm:pb-6 pb-2 px-4">
				<div className="flex justify-between flex-col md:flex-row">
					<div>
						<LogoImg src={Logo} alt="La Gondola" />
						<div className="flex flex-col xl:mt-12 mt-6 gap-3">
							<div className="flex items-center gap-4">
								<img src={Lokalizacja} alt="lokalizacja" />
								<InfoText className="text-mediumColor">
									ul. Kościelna 1, Zielona Góra
								</InfoText>
							</div>
							<div className="flex items-center gap-4">
								<img src={Email} alt="email" />
								<InfoText className="text-mediumColor">
									pizza@lagondola.zgora.pl
								</InfoText>
							</div>
							<div className="flex items-center gap-4">
								<img src={Telefon} alt="telefon" />
								<InfoText className="text-mediumColor">(68) 320 20 12</InfoText>
							</div>
						</div>
						<div className="xl:mt-12 mt-6">
							<Title className="text-mediumColor">{t("footer.follow")}</Title>
						</div>
						<div className="flex gap-12 mt-2">
							<a
								href={t("link.facebook")}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img className="cursor-pointer" src={Facebook} alt="facebook" />
							</a>
							<a
								href={t("link.instagram")}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									className="cursor-pointer"
									src={Instagram}
									alt="instagram"
								/>
							</a>
						</div>
					</div>

					<div>
						<div className="mt-6 md:mt-0">
							<Title className="text-mediumColor">
								{t("footer.open.title")}
							</Title>
							<div className="relative mt-8">
								<DeliveryDiv className="absolute">
									<span>Dowóz</span>
								</DeliveryDiv>
								<div className="flex justify-between">
									<div>
										<TimeText>{t("footer.open.day1")}</TimeText>
									</div>
									<div>
										<TimeText>
											11:00 - 22:00 <DeliveryTimeSpan>(23:00)</DeliveryTimeSpan>
										</TimeText>
									</div>
								</div>
								<div className="flex justify-between">
									<div>
										<TimeText>{t("footer.open.day2")}</TimeText>
									</div>
									<div>
										<TimeText>
											11:00 - 22:00 <DeliveryTimeSpan>(23:00)</DeliveryTimeSpan>
										</TimeText>
									</div>
								</div>
								<div className="flex justify-between">
									<div>
										<TimeText>{t("footer.open.day3")}</TimeText>
									</div>
									<div>
										<TimeText>
											11:00 - 22:00 <DeliveryTimeSpan>(23:00)</DeliveryTimeSpan>
										</TimeText>
									</div>
								</div>
								<div className="flex justify-between">
									<div>
										<TimeText>{t("footer.open.day4")}</TimeText>
									</div>
									<div>
										<TimeText>
											11:00 - 22:00 <DeliveryTimeSpan>(23:00)</DeliveryTimeSpan>
										</TimeText>
									</div>
								</div>
								<div className="flex justify-between">
									<div>
										<TimeText>{t("footer.open.day5")}</TimeText>
									</div>
									<div>
										<TimeText>
											11:00 - 23:00 <DeliveryTimeSpan>(24:00)</DeliveryTimeSpan>
										</TimeText>
									</div>
								</div>
								<div className="flex justify-between">
									<div>
										<TimeText>{t("footer.open.day6")}</TimeText>
									</div>
									<div>
										<TimeText>
											11:00 - 23:00 <DeliveryTimeSpan>(24:00)</DeliveryTimeSpan>
										</TimeText>
									</div>
								</div>
								<div className="flex justify-between">
									<div>
										<TimeText>{t("footer.open.day7")}</TimeText>
									</div>
									<div>
										<TimeText>
											11:00 - 23:00 <DeliveryTimeSpan>(24:00)</DeliveryTimeSpan>
										</TimeText>
									</div>
								</div>
							</div>
						</div>
						<div className="xl:mt-12 mt-6">
							<Title className="text-mediumColor">{t("footer.download")}</Title>
							<div className="flex flex-col gap-5 mt-2">
								<div className="gap-5 flex flex-col sm:flex-row items-start sm:items-center">
									<Link to="/online">
										<AppsImg src={Pyszne} alt="pyszne" />
									</Link>
									<Link to="/online">
										<AppsImg src={Glovo} alt="glovo" />
									</Link>
								</div>
								<div className="gap-5 flex flex-col sm:flex-row items-start sm:items-center">
									<a
										href={t("link.ios")}
										target="_blank"
										rel="noopener noreferrer"
									>
										<AppsImg className="flex" src={AppStore} alt="appstore" />
									</a>
									<a
										href={t("link.android")}
										target="_blank"
										rel="noopener noreferrer"
									>
										<AppsImg src={GooglePlay} alt="googleplay" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex justify-center sm:gap-2 gap-1 mt-6 flex-col sm:flex-row items-center">
					<BottomText>Copyright © 2025 La Gondola</BottomText>
					{isUnder640px === false && <BottomText>|</BottomText>}
					<BottomText>All Rights Reserved</BottomText>
					{isUnder640px === false && <BottomText>|</BottomText>}
					<BottomText>
						Realizacja:{" "}
						<a
							href="https://futureup.pl"
							target="_blank"
							rel="noopener noreferrer"
							style={{ color: "#004fc5" }}
						>
							FutureUp
						</a>
					</BottomText>
				</div>
			</div>
		</MainDiv>
	);
}

export default Footer;
